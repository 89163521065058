<template>
  <v-client-table
    id="dataTable"
    :data="data"
    :options="options"
    :columns="columns"
    :theme="theme"
  >
    <span
      slot="planCompletePercent"
      slot-scope="props"
    >
      <span v-if="props.row.plannedRoutesNumber">
        {{ numberRUFormat(((props.row.startingPriceSum-props.row.endPriceSum) / props.row.plannedProfit * 100).toFixed(2)) }}%
      </span>
      <span v-else>100%</span>
    </span>

    <span 
      slot="startingPriceSum"
      slot-scope="props"
    >
      {{ numberRUFormat(Math.round(props.row.startingPriceSum)) }}
    </span>
    <span 
      slot="endPriceSum"
      slot-scope="props"
    >
      {{ numberRUFormat(Math.round(props.row.endPriceSum)) }}
    </span>
    <span
      slot="profit"
      slot-scope="props"
    >
      {{ numberRUFormat(Math.round(props.row.profit)) }}
    </span>
    <span
      slot="savingOverrun"
      slot-scope="props"
    >
      <span
        v-if="(props.row.startingPriceSum - props.row.endPriceSum) > 0"
        class="text-success"
      >{{ numberRUFormat(Math.round(props.row.startingPriceSum - props.row.endPriceSum)) }} руб.</span>
      <span
        v-else-if="(props.row.startingPriceSum - props.row.endPriceSum) < 0"
        class="text-danger"
      >{{ numberRUFormat(Math.round(props.row.startingPriceSum - props.row.endPriceSum)) }} руб.</span>
      <span v-else>0 руб.</span>
    </span>
    <!-- <tr
      slot="appendBody"
    >
      <td />
      <td><b>{{ total.routesNumber }}</b></td>
      <td>
        <b>{{ total.plannedRoutesNumber }}</b>
      </td>
      <td><b>{{ total.acceptedRoutesNumber }}</b></td>
      <td>
        <span v-if="total.plannedProfit">
          <b>{{ ((total.startingPriceSum -total.endPriceSum) / total.plannedProfit * 100).toFixed(2) }}%</b></span>
        <span v-else><b>100%</b></span>
      </td>
      <td>
        <b>{{ total.bidsNumber }}</b>
      </td>
      <td><b>{{ total.startingPriceSum }} руб.</b></td>
      <td><b>{{ total.endPriceSum }} руб.</b></td>
      <td>
        <span
          v-if="(total.startingPriceSum -total.endPriceSum) > 0"
          class="text-success"
        ><b>{{ (total.startingPriceSum -total.endPriceSum) }} руб.</b></span>
        <span
          v-else-if="(total.startingPriceSum -total.endPriceSum) < 0"
          class="text-danger"
        ><b>{{ (total.startingPriceSum -total.endPriceSum) }} руб.</b></span>
        <span v-else><b>{{ (total.startingPriceSum -total.endPriceSum) }} руб.</b></span>
      </td>
    </tr> -->
  </v-client-table>
</template>

<script>
import {ClientTable, Event} from 'vue-tables-2';
import {numberToRUFormat} from '@/components/mixins/helpers';
import Vue from 'vue';
import moment from 'moment';

Vue.use(ClientTable);


export default {
  name: 'AuctionCategoryTable',
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    total: {
      type: Object,
      default: function() {
        return {};
      },
    },
    period: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columns: [
        'name',
        'totalAuctions',
        'winnerAuctions',
        'profit',
      ],
      options: {
        headings: {
          'name': 'Перевозчик',
          'totalAuctions': 'Кол-во рейсов, где был участником',
          'winnerAuctions': 'Кол-во рейсов, утвержденных',
          'profit': 'Экономия/Перерасход',
        },
        sortable: [
          'name',
          'totalAuctions',
          'winnerAuctions',
          'profit',
        ],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      theme: 'bootstrap4',
    };
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    formatWeek(date) {
      return date ? moment(date).format('WW Неделя YYYY') : '';
    },
    formatMonth(date) {
      return date ? moment(date).format('MM.YYYY') : '';
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    }
  },
};
</script>
